import React, { FC, useCallback, useContext } from "react";
import axios from "axios";

import { useTranslation } from "react-i18next";

import Text from "../../components/Typography/Text";

import Container from "../Container";
import useBox from "../../uses/useBox";
import useSWR from "swr";
import UseNavigation from "../../uses/useNavigation";

import { IFooter } from "./interfaces";

import { NavContext } from "../../providers/NavProvider";
import { INavContext } from "../../providers/NavProvider/interfaces";

import { LogoFooter } from "../../assets/icons/logo-footer";
import SiriusLogo from "../../assets/icons/sirius-logo";

import "./styles.scss";

// footer
const Footer: FC<IFooter> = ({ lang, type }) => {
  const { REACT_APP_BASE_API }: any = process.env;
  const { getUrl } = UseNavigation();

  const server = getUrl(`${lang?.lang}`, REACT_APP_BASE_API);

  const { t }: any = useTranslation();

  const fetcher = (url: string) => axios.get(url).then(({ data }: any) => data);

  const { data }: any = useSWR(`${server}/api/v2/footer-content/`, fetcher);

  const { ref }: any = useBox();

  const filter = (type: string) =>
    data.filter((footer_item: any) => footer_item.type === type);

  const factoryLink = useCallback(
    (url: string) => {
      const src: string = url.replace(/.*\/\/[^/]*/, "");

      return `/${lang?.lang}${src.slice(0, -1)}`;
    },
    [lang]
  );

  // render
  return (
    <>
      <footer className="footer" data-type={type} ref={ref}>
        <Container>
          <div className="footer-top">
            <div className="footer-top-wrapper">
              <div className="logo">
                <LogoFooter />
              </div>

              {data && filter("company_information").length !== 0 && (
                <div className="info">
                  <span>CNL</span>

                  <ul>
                    {data &&
                      filter("company_information").map(
                        (item: any, index: number) => (
                          <li key={index}>
                            <img src={item?.icon_url} alt={item?.icon_alt} />
                            <Text attrs={{ className: 'description' }} type="danger" text={item?.description} />
                          </li>
                        )
                      )}
                  </ul>
                </div>
              )}

              {data &&
                (
                  filter("social_media").length !== 0 ||
                  filter("company_partners")
                ).length !== 0 && (
                  <div className="social">
                    {data && filter("social_media").length !== 0 && (
                      <div className="social-media">
                        <span>Redes sociais</span>

                        <ul>
                          {data &&
                            filter("social_media").map(
                              (item: any, index: number) => (
                                <li key={index}>
                                  <a href={item?.url} target="_blank">
                                    <img
                                      src={item?.icon_url}
                                      alt={item?.icon_alt}
                                    />
                                  </a>
                                </li>
                              )
                            )}
                        </ul>
                      </div>
                    )}

                    {data && filter("company_partners").length !== 0 && (
                      <div className="partners">
                        <span>Parceiros</span>

                        <ul>
                          {data &&
                            filter("company_partners").map(
                              (item: any, index: number) => (
                                <li key={index}>
                                  <a href={item?.url} target="_blank">
                                    <img
                                      src={item?.icon_url}
                                      alt={item?.icon_alt}
                                    />
                                  </a>
                                </li>
                              )
                            )}
                        </ul>
                      </div>
                    )}
                  </div>
                )}

              {data && filter("menu_column").length !== 0 && (
                <div className="more">
                  <span>Acesse</span>

                  <ul>
                    {data &&
                      filter("menu_column").map((item: any, index: number) => (
                        <li key={index}>
                          {item?.external_link && !item?.internal_link_url ? (
                            <a href={item?.external_link} target="_blank">
                              {item?.title}
                            </a>
                          ) : item?.internal_link_url ? (
                            <a href={factoryLink(item?.internal_link_url)}>
                              {item?.title}
                            </a>
                          ) : (
                            <a href="#">{item?.title}</a>
                          )}
                        </li>
                      ))}
                  </ul>
                </div>
              )}
            </div>
          </div>

          <div className="footer-bottom">
            <div className="footer-bottom-wrapper">
              <div className="copy">
                <Text>{t("copy")}</Text>
              </div>

              {data && filter("footer_link").length !== 0 && (
                <div className="terms">
                  <ul>
                    {data &&
                      filter("footer_link").map((item: any, index: number) => (
                        <li key={index}>
                          {item?.external_link && !item?.internal_link_url ? (
                            <a href={item?.external_link} target="_blank">
                              {item?.title}
                            </a>
                          ) : item?.internal_link_url ? (
                            <a href={factoryLink(item?.internal_link_url)}>
                              {item?.title}
                            </a>
                          ) : (
                            <a href="#">{item?.title}</a>
                          )}
                        </li>
                      ))}
                  </ul>
                </div>
              )}

              <a
                href="https://www.sirius.com.br/"
                className="signature"
                target="_blank"
              >
                <SiriusLogo />
              </a>
            </div>
          </div>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
